<template>
    <div>
        <h3 class="title is-4">Interviews
            <button class="button is-success is-small" @click="showAddForm = true" :disabled="loading==true || working==true" v-if="showAddForm == false">Add New Interview</button>
            <button class="button is-danger is-small" @click="showAddForm = false" :disabled="loading==true || working==true" v-if="showAddForm == true">Exit without Saving</button>

        </h3>
        
        <div class="notification is-warning" v-if="loading==true">Loading interviews ... </div>
        <div class="notification is-info" v-if="loading==false && interviews.length == 0 && this.showAddForm == false">There are no interviews to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && interviews.length > 0 && this.showAddForm == false" >
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Interviewer</th>
                    <th>Status</th>
                    <th>Notes</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in interviews" :key="row.id">
                    <td>{{ row.date}}</td>
                    <td>{{ row.time}}</td>
                    <td>{{ row.interviewer}}</td>
                    <td>{{ row.status}}</td>
                    <td>{{ row.notes}}</td>
                    
                </tr>
                
            </tbody>
        </table>
       

        <div class="box" v-if="this.showAddForm == true">	
              <form @submit.prevent="saveMemberInterview()">
                   
                     
                      
                      <div class="field " >
                          <label class="label">Interviewer</label>
                          <div class="control">
                              <select v-model="user_id" class="input" required>
                                <option value="">Please select ...</option>
                                <option v-for="row in users" :key="row.id" :value="row.id">{{ row.forename}} {{ row.surname }}</option>
                                </select>
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Date</label>
                          <div class="control">
                              <input class="input" type="date" v-model="date" required >
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Time</label>
                          <div class="control">
                            <input class="input" type="time" v-model="time" required >
                          </div>
                      </div>
                      <div class="field " >
                          <label class="label">Status</label>
                          <div class="control">
                              <select v-model="status_id" class="input" required>
                                <option value="">Please select ...</option>
                                <option value="1">Arranged</option>
                                <option value="2">Completed</option>
                                <option value="3">Cancelled</option>
                                
                                </select>
                          </div>
                      </div>
                      <div class="field" >
                          <label class="label">Notes</label>
                          <div class="control">
                              <textarea class="textarea" v-model="notes"  ></textarea>
                          </div>
                      </div>

                      <div class="field is-grouped" >
                          <div class="control">
                              <button type="submit" class="button is-success" :disabled="working===true">Save Interview</button>
                          </div>
                      </div>
                  </form>
            
          </div>

    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'Interviews',
  data() {
    return {
      loading: true,
      working: false,
      interviews: [],
      showAddForm: false,
      date: '',
      time: '',
      user_id: '',
      users: '',
      status_id: 1,
      notes: ''
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id', 'member_application_id'],
  methods: {
    async getUsers() {
                UtilityService.getSimpleApiData(this.accessToken, "users?no_sports=1&interviewer=1").then(
                    response => this.users = response 
                );
                
            },
       
        async getMemberInterviews() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/interviews")
            .then(
                (interviews => {
                    this.$set(this, "interviews", interviews);
                    this.loading=false
                    this.working = false

                    this.user_id = ''
                    this.date = ''
                    this.time = ''
                    this.status_id = ''
                    
                    this.notes = ''
                }).bind(this)
            );
        },
        async updateEmergencyContact(member_id, emergency_contact_id) {
			this.working = true

            if(confirm("Are you sure you want to delete this contact ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    active : 0
                }; 

                MemberService.updateMemberEmergencyContact(this.accessToken, formdata, member_id, emergency_contact_id)
                .then((response) => {
                    this.getMemberEmergencyContacts()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false;
                return false
            }
        },
        async saveMemberInterview() {
                this.working = true
                var formdata = { 
                   
                   member_id: this.member_id,
                   user_id: this.user_id,
                   date: this.date,
                   time: this.time,
                   status_id: this.status_id,
                    notes: this.notes,
                    member_application_id: this.member_application_id 
                }; 

                MemberService.saveMemberInterview(this.accessToken, this.member_id, formdata)
                .then((response) => {
                    
                    this.getMemberInterviews()
                    this.showAddForm = false
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            },
      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberInterviews()
                this.getUsers()
            });
        
    },
    computed : {
       
    }
}
</script>