<template>
    <div>
        <h2 class="title">Last Worked Report</h2>

       
        <div class="notification is-warning" v-if="loading == true">
            Loading members ...
        </div>
        <div class="notification is-danger" v-if="loading == false && this.members.length == 0">
            No members can be found
        </div>

        <table class="table is-fullwidth is-hoverable" v-if="loading == false && this.members.length > 0">
            <thead>
                <tr>
                    <th width="50"></th>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Email Address</th>
                    <th>Last Worked</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in this.members" :key="row.id" :class="{'has-background-danger-dark' : row.blocked==1, 'has-text-white' : row.blocked==1}">
                    <td><router-link :to="'/members/' + row.id" target="_blank"><button class="button is-small is-link" >View</button></router-link></td>
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.last_worked_date }}</td>
                    <td>
                        <span class="tag is-success" v-if="row.last_worked_status == 1 ">Less Than 6 Months</span>
                        <span class="tag is-danger" v-if="row.last_worked_status == 2 ">More Than 6 Months</span>
                        <span class="tag is-warning" v-if="row.last_worked_status == 3 ">Never Worked</span>

                    </td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
//import MemberService from '@/services/MemberService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'MembersDashboard',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   members: [],
                   loading: false,
                  
                   showModal: false,
                   order_by: "nmc_expiry ASC"
            }
        },
        methods: {
           
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMembersWithLastWorkedDate(this.order_by)
            });
        
        },
        computed : {
                filtered_members: function () {

                    let filtered_members =  this.members;
                
                /*let filtered_members =  this.members.filter(function(u) {
                    if(  u.active == 1) 
                    {
                    return true
                    }
                    else
                    {
                    return false
                    }
                })

                if(this.search != '' && this.search != null)
                {
                    filtered_members = filtered_members.filter(
                        row => (row.name != null && row.name.toLowerCase().includes(this.search.toLowerCase() ) ) 
                        || (row.surname != null && row.surname.toLowerCase().includes(this.search.toLowerCase() )) 
                        || (row.email != null && row.email.toLowerCase().includes(this.search.toLowerCase() ) )
                    )
                }*/

                return filtered_members
            
            },
        }
    }
</script>